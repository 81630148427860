const state = {
  isCheck: false,
  prize_draw_process: null,
}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_PRIZE_DRAW_PROCESSING(state, payload) {
    state.isCheck = true
    state.prize_draw_process = payload
  },
}

const actions = {
  setPrizeDrawInProcess: async ({ commit }, prizeDrawInProcess) => {
    localStorage.setItem('prize-draw-processing', JSON.stringify(prizeDrawInProcess))
    commit('SET_PRIZE_DRAW_PROCESSING', prizeDrawInProcess)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
